import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps, Image, Upload, notification } from 'antd';
import { getBase64 } from 'src/helpers';
import { FileType } from 'src/interfaces';
import { ProductApi } from 'src/api';
import { isNaN } from 'lodash';

interface IFileUploadProps {
    action: string;
    files: UploadFile[];
    onRemove: (id: string) => void;
}

export const FileUpload: React.FC<IFileUploadProps> = ({ action, files, onRemove }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>();

    const handlePreview = async (file: UploadFile): Promise<void> => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handleRemove = (file: UploadFile): boolean => {
        if (isNaN(Number(file.uid))) {
            notification.error({
                message: 'Видалення здійснити наразі неможливо',
                description: 'Для видалення щойно завантаженого файлу необхідно оновити сторінку ',
            });
            return false;
        } else {
            onRemove && onRemove(file.uid);
            return true;
        }
    };

    useEffect(() => {
       if (files && !fileList) {
           setFileList(files);
       }
    }, [setFileList, fileList, files]);

    return (
        <>
            <Upload
                action={ProductApi.apiUrl + action}
                listType="picture-card"
                fileList={fileList}
                withCredentials
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
                multiple
            >
                <button style={{ border: 0, background: 'none' }} type="button">
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                </button>
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    alt={'Medbuy'}
                    src={previewImage}
                />
            )}
        </>
    );
};
