import { BaseApi } from '.';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';
import { IOM_Info, IOM_Order } from 'main/services/OrderManager';
import { IDiscount } from 'main/interfaces';

class OrderApi extends BaseApi {
    constructor() {
        super('/order');
    }

    _getFilters(params: IBaseGetAllParams): string {
        return Object.entries(params ?? {}).reduce((acc, [key, value]) => {
            acc += value ? `${acc === '' ? '?' : '&'}${key}=${value}` : '';
            return acc;
        }, '');
    }

    async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<IOM_Order>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IOM_Order>>>(`${this._getFilters(params)}`))?.data);
    }

    async copy(id: string): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/' + id + '/copy'))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IOM_Info> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IOM_Info>>('/' + id, data))?.data);
    }

    async updateStatus(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/' + id + '/status', data))?.data);
    }

    async getOne(id: string): Promise<ApiBaseResponse<IOM_Info> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IOM_Info>>('/' + id))?.data);
    }

    async checkDeliveryStatus(id: string): Promise<ApiBaseResponse<IOM_Info> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IOM_Info>>('/' + id + '/check-delivery'))?.data);
    }

    async updateTTN(order_id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/' + order_id + '/ttn', data))?.data);
    }

    async addProduct(order_id: number, values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/' + order_id + '/product', values))?.data);
    }

    async deleteProduct(order_id: number, order_product_id: number): Promise<ApiBaseResponse<boolean> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<boolean>>('/' + order_id + '/product/' + order_product_id))?.data);
    }

    async updateProduct(order_id: number, id_order_product: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/' + order_id + '/product/' + id_order_product, data))?.data);
    }

    async changeInvoice(order_id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/' + order_id + '/invoice', data))?.data);
    }

    async addDiscount(order_id: number, values: Record<string, IDiscount>): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/' + order_id + '/discount', values))?.data);
    }

    async addProductLot(order_product_id: number, values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/product-lot/' + order_product_id, values))?.data);
    }

    async deleteProductLot(order_product_lot_id: number): Promise<ApiBaseResponse<boolean> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<boolean>>('/product-lot/' + order_product_lot_id))?.data);
    }

    async updateProductLot(order_product_id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/product-lot/' + order_product_id, data))?.data);
    }
}

export const orderApi = new OrderApi();
