import './TextEditor.scss';

import React from 'react';
import Editor, { ContentEditableEvent } from 'react-simple-wysiwyg';
import decode from 'html-entities-decode';

interface ITextEditorProps {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    onBlur?: (value: string) => void;
}

export const TextEditor: React.FC<ITextEditorProps> = (
    {
        value,
        onChange,
        disabled,
        onBlur,
    }) => {
    const [editorState, setEditorState] = React.useState(decode(value));

    const handleChange = (event: ContentEditableEvent): void => {
        onChange(event.target.value);
        setEditorState(event.target.value);
    };

    const handleBlur = (): void => {
        onBlur && onBlur(editorState);
    };

    return (
        <Editor
          value={editorState}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
    );
};
