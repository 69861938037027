import React from 'react';
import { FloatButton, Popover } from 'antd';
import { OrderDocuments, OrderDocumentsChangeModal, OrderTTN } from '../';
import { IOM_Order } from 'main/services/OrderManager';
import { TruckOutlined, FileTextOutlined, FileAddOutlined } from '@ant-design/icons';

interface IOrderDetailButtons {
  order: IOM_Order;
  onChange: () => void;
}

export const OrderDetailButtons: React.FC<IOrderDetailButtons> = ({ order, onChange }) => {
  const handleChange = (): void => {
    onChange();
  };

  return (
    <FloatButton.Group shape="circle" target={'header'}>
      <OrderTTN order={order} onChange={handleChange}>
        <FloatButton badge={{ count: '!' }} icon={<TruckOutlined/>} type={'primary'}/>
      </OrderTTN>
      <OrderDocumentsChangeModal order_id={order.id} onChange={handleChange}>
        <FileAddOutlined />
      </OrderDocumentsChangeModal>
      <Popover
        content={<OrderDocuments documents={order.documents ?? []}/>}
        placement={'topLeft'}
        trigger="click"
      >
        <FloatButton tooltip={'Документи'} badge={{ count: order?.documents?.length }} icon={<FileTextOutlined/>} type={'primary'}/>
      </Popover>
    </FloatButton.Group>
  );
};
