import { BaseApi } from '.';
import { IWarehouse, IWarehouseAction, IWarehouseActionProduct, IWarehouseLot } from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';

class WarehouseApi extends BaseApi {
    constructor() {
        super('/warehouse');
    }

    async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<IWarehouse>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IWarehouse>>>('/' + (params?.type ? '?type=' + params?.type : '') + (params?.id_product ? '&lots=id_product,' + params?.id_product : '')))?.data);
    }

    async getOne(id: string, id_product?: number): Promise<ApiBaseResponse<IWarehouse> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IWarehouse>>('/' + id + (id_product ? '?id_product=' + id_product : '')))?.data);
    }

    async getProducts(warehouse_id?: string, page?: number, pageSize?: number): Promise<ApiBaseResponse<ApiWithPaginationResponse<IWarehouseLot>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IWarehouseLot>>>('/lots?id_warehouse=' + warehouse_id + (page ? '&page=' + page : '') + (page ? '&limit=' + pageSize : '')))?.data);
    }

    async getActions(page?: number): Promise<ApiBaseResponse<ApiWithPaginationResponse<IWarehouseAction>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IWarehouseAction>>>('/warehouse-action' + (page ? '&page=' + page : '')))?.data);
    }

    async getActionProducts(warehouse_action_id: number): Promise<ApiBaseResponse<IWarehouseActionProduct[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IWarehouseActionProduct[]>>('/warehouse-action/' + warehouse_action_id + '/products'))?.data);
    }

    async receiving(data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/receiving', data))?.data);
    }

    async write_off(data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/write-off', data))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IWarehouse> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IWarehouse>>('/' + id, data))?.data);
    }
}

export const warehouseApi = new WarehouseApi();
