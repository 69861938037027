import React from 'react';
import { IOM_OrderDocument } from 'main/services/OrderManager/OrderManager';
import { Col, Row, Space } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface IOrderDocumentsProps {
  documents: IOM_OrderDocument[];
}

export const OrderDocuments: React.FC<IOrderDocumentsProps> = ({ documents }) => {
  return (
    <Row>
      {documents.map((document) => (
        <Col key={document.id} span={24}>
          <Space style={{ margin: '15px 0' }}>
            <FilePdfOutlined/>
            <Link to={document.link} target={'_blank'}>{document.name}</Link>
          </Space>
        </Col>
      ))}
    </Row>
  );
};
