import moment from 'moment-timezone';
import 'moment/locale/uk';
import { amountToWords } from './amountToWords';
moment.locale('uk');

export class Formatter {
    private static readonly currency_icon: string = '€';

    static toMoney(value: number, withoutCurrency?: boolean): string {
        return `${value.toFixed(2)}${withoutCurrency ? '' : ' ' + Formatter.currency_icon}`;
    }

    static toDate(value: string | Date, withoutTime?: boolean): string {
        return moment.utc(value as string).tz('Europe/Kyiv').format(withoutTime
            ? 'DD.MM.YYYY'
            : 'DD-MM-YYYY HH:mm:ss'
        );
    }

    static amountToWords(amount: number):string {
        return amountToWords(amount);
    }
}
