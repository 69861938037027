import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IOM_Info, IOM_OrderProduct } from 'main/services/OrderManager';
import { Table } from 'src/base-component';
import { Typography, TableProps, Button, Divider, Switch } from 'antd';
import { ROUTES } from 'src/constants';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';
import { DeleteFilled } from '@ant-design/icons';
import { OrderEditProductsPrice } from './OrderEditProductsPrice';
import { OrderEditProductsCount } from './OrderEditProductsCount';
import { OrderEditProductsAdd } from './OrderEditProductsAdd';
import { IDiscount, IProduct } from 'main/interfaces';
import { ProductApi } from 'src/api';
import { OrderEditProductsLots } from '../OrderEditProductsLots/OrderEditProductsLots';
import { Formatter } from 'src/helpers';
import { OrderDiscounts } from '../OrderDiscounts/OrderDiscounts';

const { Title } = Typography;

interface IOrderEditProductsProps {
    order: IOM_Info;
    onAddProduct: (product: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
    onChangeDiscount: (product: Record<string, IDiscount>) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
    onDeleteProduct: (order_product_id: number) => void;
    onChangeProduct: (product: BaseApiPostData) => void;
    disabled: boolean;
    onAddLot: (order_product_id: number, lot: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
    onDeleteLot: (order_product_lot_id: number) => void;
    onChangeLot: (order_product_id: number, lot: BaseApiPostData) => void;
}

export const OrderEditProducts: React.FC<IOrderEditProductsProps> = ({ order, onChangeLot, onDeleteLot, onAddLot, onChangeDiscount, onAddProduct, disabled, onDeleteProduct, onChangeProduct }) => {
    const [products, setProducts] = useState<IProduct[] | undefined>();
    const { products: order_products, discounts } = order;
    const orderProducts = order_products?.map((order_product) => ({ ...order_product, key: order_product.id })) ?? [];

    const getProducts = useCallback((value?: string) => {
        ProductApi.getAll({ page: 1, name: value }).then((res) => {
            setProducts(res?.data?.rows);
        });
    }, []);

    const handleDelete = (order_product_id: number): () => void => {
        return (): void => {
            onDeleteProduct(order_product_id);
        };
    };

    const handleChange = (product: BaseApiPostData): void => {
        onChangeProduct(product);
    };

    const handleAdd = async (product: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        return await onAddProduct(product);
    };

    const handleChangeDiscount = async (discount: Record<string, IDiscount>): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        return await onChangeDiscount(discount);
    };

    const handleDeleteLot = (order_product_lot_id: number): void => {
        onDeleteLot(order_product_lot_id);
    };

    const handleChangeWithDiscount = (product: BaseApiPostData): (value: boolean) => void => {
        return (with_discount: boolean): void => {
            onChangeProduct({ ...product, with_discount });
        };
    };

    const handleChangeLot = (order_product_lot_id: number, values: BaseApiPostData): void => {
        return onChangeLot(order_product_lot_id, values);
    };

    const handleAddLot = (order_product_id: number): (values: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
        return (values: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> => {
            return onAddLot(order_product_id, values);
        };
    };

    const handleSearchProduct = useCallback((value: string): void => {
        getProducts(value);
    }, [getProducts]);

    useEffect(() => {
        if (!products) {
            getProducts();
        }
    }, [products, getProducts]);

    const columns: TableProps<IOM_OrderProduct>['columns'] = [
        {
            title: 'Назва',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (<a href={ROUTES.CATALOG_PRODUCTS + '/edit/' + record.id_product}>{record.name}</a>)
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Кількість',
            dataIndex: 'count',
            key: 'count',
            render: (_, record) => disabled ? record.count : (
                <OrderEditProductsCount product={record} onChange={handleChange}/>
            ),
        },
        {
            title: 'Ціна',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => disabled || !record.with_discount ? Formatter.toMoney(record.base_price ?? record.price, true) : (
                <OrderEditProductsPrice product={record} onChange={handleChange} />
            ),
        },
        {
            title: '---',
            dataIndex: 'with_discount',
            key: 'with_discount',
            render: (_, record) => (<Switch value={record.with_discount} onChange={handleChangeWithDiscount({ id: record.id })} />),
        },
        {
            title: 'Ціна з знижкою',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => Formatter.toMoney(record.price, true),
        },
        {
            title: 'Cума знижки',
            dataIndex: 'discount_total',
            key: 'discount_total',
            render: (_, record) => Formatter.toMoney(record.discount_total ?? 0, true),
        },
        {
            title: 'Cума без знижки',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => record.total_price,
        },
        {
            title: 'Cума з знижкою',
            dataIndex: 'amount_with_discount',
            key: 'amount_with_discount',
            render: (_, record) => Formatter.toMoney(record.count * record.price, true),
        },
        {
            title: 'Дії',
            key: 'action',
            render: (_, record) => disabled ? null : (
                <Button onClick={handleDelete(record.id)}>
                    <DeleteFilled/>
                </Button>
            ),
        },
    ];

    const handleExpandable = (record: IOM_OrderProduct): ReactNode => {
        return <OrderEditProductsLots product_id={record.id_product}
                                      order_products_lots={record.lots ?? []}
                                      onDelete={handleDeleteLot}
                                      disabled={disabled}
                                      onChange={handleChangeLot}
                                      onAdd={handleAddLot(record.id)}
               />;
    };

    return (
        <>
            <Title level={5}>Товари в замовлені:</Title>
            <Table columns={columns} data={orderProducts} loading={false} onExpandable={handleExpandable}/>
            {discounts && <OrderDiscounts order={order} onChangeDiscount={handleChangeDiscount}/>}
            <Divider/>
            <Title level={5}>Додати товар:</Title>
            <OrderEditProductsAdd products={products ?? []} onSearch={handleSearchProduct} onAdd={handleAdd}/>
        </>
    );
};
